import * as M from 'types/serverModels';

export function getProjectBookLinkedTaskType(
  kind: M.ProjectBookBidirectionalLinkedTaskType,
): M.ProjectBookLinkedTaskType {
  switch (kind) {
    case 'relatesTo': {
      return 'relates_to';
    }
    case 'precedes':
    case 'blocks': {
      return kind;
    }
    case 'precededBy': {
      return 'precedes';
    }
    case 'blockedBy': {
      return 'blocks';
    }
  }
}
