import { makeService } from './utils';

type InputData = {
  subject: string;
  text: string;
  login: string[];
};

export const userEmailSend = makeService<InputData, number>(
  'user_email_send',
  'post',
);
