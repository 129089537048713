import * as R from 'ramda';
import React, { useRef, useCallback, useEffect } from 'react';

import * as API from 'services/API';
import { PENDING_ACTION_STATE } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';

import { subscribers } from '../subscribers';
import { getNotOutdatedSocketNotifications } from './getNotOutdatedSocketNotifications';
import {
  callStateUnit,
  notificationsCallStateUnit,
  resolvedNotificationsCallStateUnit,
  paStateUnit,
} from './units';

type Props = {};

const initial: Record<M.Notification['ntype'], M.Notification[]> = {
  group_invitation: [],
  group_knock: [],
  private_message: [],
  project_author_invitation: [],
  project_supervisor_invitation: [],
};

function getNTypeToNotifications(notifications: M.Notification[]) {
  return Object.entries(
    notifications.reduce((acc, x) => {
      return {
        ...acc,
        [x.ntype]: [...acc[x.ntype], x],
      };
    }, initial),
  ) as [M.Notification['ntype'], M.Notification[]][];
}

function Poller({}: Props) {
  const actualNotificationsRef = useRef<M.Notification[]>([]);

  const notifySubscribers = useCallback((notifications: M.Notification[]) => {
    const ntypeToNotifications = getNTypeToNotifications(notifications);

    const actualNotificationsUUIDs = actualNotificationsRef.current.map(
      x => x.uuid,
    );
    const addedNotifications = ntypeToNotifications.map(
      ([type, value]) =>
        [
          type,
          value.filter(x => !actualNotificationsUUIDs.includes(x.uuid)),
        ] as const,
    );

    const nextNotificationsUUIDs = notifications.map(x => x.uuid);

    const removedNotifications = getNTypeToNotifications(
      actualNotificationsRef.current.filter(
        x => !nextNotificationsUUIDs.includes(x.uuid),
      ),
    );

    actualNotificationsRef.current = notifications;

    addedNotifications.forEach(([type, value]) => {
      if (value.length > 0) {
        subscribers[type].forEach(subscriber => {
          subscriber('add', value);
        });
      }
    });

    removedNotifications.forEach(([type, value]) => {
      if (value.length > 0) {
        subscribers[type].forEach(subscriber => {
          subscriber('remove', value);
        });
      }
    });
  }, []);

  const notify = useCallback(
    (notification: M.SocketNotification) => {
      switch (notification.ntype) {
        case 'project_author_invitation':
        case 'project_supervisor_invitation':
        case 'group_invitation':
        case 'group_knock': {
          notificationsCallStateUnit.setState(prev => {
            if (prev.kind !== 'successful') {
              return prev;
            }

            if (notification.state === PENDING_ACTION_STATE.pending) {
              return { ...prev, data: [notification, ...prev.data] };
            }

            return {
              ...prev,
              data: R.remove(
                prev.data.findIndex(x => x.uuid === notification.uuid),
                1,
                prev.data,
              ),
            };
          });

          if (
            notification.state !== PENDING_ACTION_STATE.pending &&
            paStateUnit.getState() === '*'
          ) {
            resolvedNotificationsCallStateUnit.setState(prev => {
              if (prev.kind !== 'successful') {
                return prev;
              }

              return { ...prev, data: [notification, ...prev.data] };
            });
          }

          break;
        }
        case 'private_message': {
          notificationsCallStateUnit.setState(prev => {
            if (prev.kind !== 'successful') {
              return prev;
            }

            return { ...prev, data: [notification, ...prev.data] };
          });

          break;
        }
        case 'private_message_read': {
          notificationsCallStateUnit.setState(prev => {
            if (prev.kind !== 'successful') {
              return prev;
            }

            return {
              ...prev,
              data: prev.data.filter(
                x =>
                  x.ntype !== 'private_message' ||
                  !notification.uuid.includes(x.uuid),
              ),
            };
          });
        }
      }

      const callState = notificationsCallStateUnit.getState();

      if (callState.kind === 'successful') {
        notifySubscribers(callState.data);
      }
    },
    [notifySubscribers],
  );

  const accessToken = API.useAccessToken();
  const userState = userStateUnit.useState();

  const { socket } = API.socket.useUserSocket();

  const paState = paStateUnit.useState();

  const call = API.services.notificationList.useCall(callStateUnit);

  useEffect(() => {
    if (
      socket === null ||
      accessToken === null ||
      userState.kind !== 'loaded'
    ) {
      return;
    }

    let buff: M.SocketNotification[] = [];

    const handleNotification = (notification: M.SocketNotification) => {
      const callState = notificationsCallStateUnit.getState();

      if (callState.kind === 'successful') {
        notify(notification);
      } else if (callState.kind !== 'error') {
        buff = [...buff, notification];
      }
    };

    socket.on('notification', handleNotification);

    const unsubscribeCallStateUnit = notificationsCallStateUnit.subscribe({
      name: 'socket-buff',
      callback: callState => {
        if (callState.kind === 'initial' || callState.kind === 'pending') {
          return;
        }

        unsubscribeCallStateUnit();

        if (callState.kind === 'successful') {
          getNotOutdatedSocketNotifications(buff, callState).forEach(x => {
            notify(x);
          });
        }
      },
    });

    call({ cdate: new Date(0).toISOString(), pa_state: paState });

    return () => {
      socket.off('notification', handleNotification);

      unsubscribeCallStateUnit();

      callStateUnit.resetState();
      notificationsCallStateUnit.resetState();
      resolvedNotificationsCallStateUnit.resetState();
    };
  }, [socket, accessToken, userState.kind, paState, notify, call]);

  useEffect(
    () =>
      callStateUnit.subscribe({
        name: 'state-units-updater',
        callback: callState => {
          if (callState.kind !== 'successful') {
            notificationsCallStateUnit.setState(callState);
            resolvedNotificationsCallStateUnit.setState(callState);

            return;
          }

          const { notifications, resolvedNotifications } =
            callState.data.reduce<{
              notifications: M.Notification[];
              resolvedNotifications: (
                | M.ProjectInvitationNotification
                | M.GroupNotification
              )[];
            }>(
              (acc, x) => {
                switch (x.ntype) {
                  case 'project_author_invitation':
                  case 'project_supervisor_invitation':
                  case 'group_invitation':
                  case 'group_knock': {
                    return x.state === PENDING_ACTION_STATE.pending
                      ? { ...acc, notifications: [x, ...acc.notifications] }
                      : {
                          ...acc,
                          resolvedNotifications: [
                            x,
                            ...acc.resolvedNotifications,
                          ],
                        };
                  }
                  case 'private_message': {
                    return { ...acc, notifications: [x, ...acc.notifications] };
                  }
                }

                return acc;
              },
              { notifications: [], resolvedNotifications: [] },
            );

          resolvedNotificationsCallStateUnit.setState({
            ...callState,
            data: resolvedNotifications,
          });
          notificationsCallStateUnit.setState({
            ...callState,
            data: notifications,
          });
        },
      }),
    [],
  );

  return null;
}

export { notificationsCallStateUnit, resolvedNotificationsCallStateUnit };

export * from './api';

export const Component = React.memo(Poller) as typeof Poller;
