import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const i18nStatusSharedReferences = {
  assigned: makeSharedEntryReference(data => data.projectBook.status.assigned),
  onprogress: makeSharedEntryReference(
    data => data.projectBook.status.onprogress,
  ),
  finished: makeSharedEntryReference(data => data.projectBook.status.finished),
  postponed: makeSharedEntryReference(
    data => data.projectBook.status.postponed,
  ),
  deleted: makeSharedEntryReference(data => data.projectBook.status.deleted),
  null: makeSharedEntryReference(data => data.projectBook.status.null),
};

export const i18nStageStatusSharedReferences = {
  assigned: makeSharedEntryReference(
    data => data.projectBook.stage.status.assigned,
  ),
  onprogress: makeSharedEntryReference(
    data => data.projectBook.stage.status.onprogress,
  ),
  assessment: makeSharedEntryReference(
    data => data.projectBook.stage.status.assessment,
  ),
  finished: makeSharedEntryReference(
    data => data.projectBook.stage.status.finished,
  ),
  null: makeSharedEntryReference(data => data.projectBook.stage.status.null),
};

export const i18nEvaluationCriteriaSharedReferences = {
  null: makeSharedEntryReference(
    data => data.projectBook.evaluation.criteria.options.null,
  ),
  0: makeSharedEntryReference(
    data => data.projectBook.evaluation.criteria.options['0'],
  ),
  0.5: makeSharedEntryReference(
    data => data.projectBook.evaluation.criteria.options['0.5'],
  ),
  1: makeSharedEntryReference(
    data => data.projectBook.evaluation.criteria.options['1'],
  ),
};
