import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../utils';

type UserInputData = {
  login: string;
  password: string;
  email?: string;
  role: M.UserRole;
  referral_code?: string;
  notification?: boolean;
  social?: M.Social;
  language: TS.Language;
  name?: string;
  avatar?: string;
  about?: string;
};

type InputData = {
  user: UserInputData;
  pending_action?: M.UUID;
};

export const post = makeService<InputData, M.AuthData>(
  () => 'user/',
  'api-v2-post',
  false,
);
