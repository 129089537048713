import React, { useEffect } from 'react';

import { API, DataPrefetch } from 'services';
import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as Webinar from './Webinar';
import * as features from './features';

type Props = {};

const path = routeTree.LANG.webinar.page.WEBINAR_UUID.getPath();

const webinarPrefetcher = DataPrefetch.makeDataPrefetcher(
  'webinar',
  path,
  ({ webinar_uuid }) => {
    return API.services.webinarRead.callPromised({
      uuid: webinar_uuid,
    });
  },
);

function View({}: Props) {
  const routeParams = routeTree.LANG.webinar.page.WEBINAR_UUID.useRouteParams();

  const webinarUUID = routeParams && routeParams.webinar_uuid;

  const accessToken = API.useAccessToken();

  const callStateUnit = API.services.webinarRead.useCallStateUnit();

  const callState = callStateUnit.useState();

  const call = API.services.webinarRead.useCall(callStateUnit);

  useEffect(() => {
    if (webinarPrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    if (!webinarUUID) {
      return;
    }

    call({ uuid: webinarUUID });
  }, [webinarUUID, accessToken, call]);

  useEffect(
    () => () => {
      webinarPrefetcher.deleteData();
    },
    [accessToken],
  );

  const prefetchedWebinar = webinarPrefetcher.getData();
  if (prefetchedWebinar !== DataPrefetch.notFetched) {
    return <Webinar.Component webinar={prefetchedWebinar} />;
  }

  return API.renderCallState(callState, {
    successful: ({ data }) => <Webinar.Component webinar={data} />,
  });
}

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(View) as typeof View,
  features: Object.values(features).map(x => x.feature),
});
