import { useEffect, useMemo, useState } from 'react';

import { makeLogger } from 'utils/Logger';
import { useIsMountedRef } from 'utils/react';

import { getNewState } from '../getNewState';
import { notifySubscribers } from '../notifySubscribers';
import {
  SetStateValue,
  StateSubscriber,
  StateSubscriberCallback,
  UnitDebugData,
} from '../types';
import { PrimaryStateUnit } from './types';

const notInitialized = Symbol('not-initialized');
type NotInitialized = typeof notInitialized;

const forwarderToOriginName = 'forwarder-to-origin';
const forwarderToBridgeName = 'forwarder-to-bridge';

export function makePrimaryUnit<T>(
  initialState: T | (() => T),
  debugData?: UnitDebugData,
  filterPredicate?: (x: T) => boolean, // TODO refactor args
): PrimaryStateUnit<T> {
  const { name, debugMode = true } = debugData || {
    name: 'not-specified',
    debugMode: false,
  };

  const getInitialState =
    typeof initialState === 'function'
      ? (initialState as () => T)
      : () => initialState;

  const logger = makeLogger(name, debugMode);

  logger.log('initializing', initialState);

  let unitState: T | NotInitialized = notInitialized;

  let subscribersToNotify: Array<StateSubscriber<T>> = [];

  let subscribers: Array<StateSubscriber<T>> = [];

  const subscribe = (callback: StateSubscriberCallback<T>, name: string) => {
    const subscriber: StateSubscriber<T> = { callback, name };
    logger.log(`(${name}) is subscribing`);
    subscribers.push(subscriber);
    logger.log(`(${name}) subscribers after subscribe`, subscribers);

    return () => {
      logger.log(`(${name}) is unsubscribing`);
      subscribers = subscribers.filter(x => x !== subscriber);

      subscribersToNotify = subscribersToNotify.filter(x => x !== subscriber);
    };
  };

  const subscribeStart = (
    callback: StateSubscriberCallback<T>,
    name: string,
  ) => {
    const subscriber: StateSubscriber<T> = { callback, name };
    logger.log(`(${name}) is subscribing`);
    subscribers.unshift(subscriber);

    logger.log(`(${name}) subscribers after subscribe`, subscribers);

    return () => {
      logger.log(`(${name}) is unsubscribing`);
      subscribers = subscribers.filter(x => x !== subscriber);

      subscribersToNotify = subscribersToNotify.filter(x => x !== subscriber);
    };
  };

  const getUnitState = () =>
    unitState === notInitialized ? getInitialState() : unitState;

  const setState = (value: SetStateValue<T>, ignoreList?: string[]) => {
    const prevState = getUnitState();

    const newState = getNewState(value, prevState);

    if (filterPredicate === undefined || filterPredicate(newState)) {
      unitState = newState;
      logger.warn('set state', newState, subscribers);

      subscribersToNotify =
        ignoreList === undefined
          ? subscribers.slice()
          : subscribers.filter(x => !ignoreList.includes(x.name));

      notifySubscribers(subscribersToNotify, newState, prevState, logger);
    }
  };

  const useUnitState = (name: string = 'name-is-not-specified'): T => {
    const [state, setState] = useState<T>(getUnitState);

    const isMountedRef = useIsMountedRef();

    useEffect(
      () => {
        const callback = (value: T) => {
          if (isMountedRef.current) {
            setState(value);
          }
        };

        if (state !== unitState) {
          logger.log(
            `(${name}) has outdated state, notifying immediatly with value`,
            unitState,
          );

          callback(getUnitState());
        }

        return subscribe(callback, name);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [name, subscribe],
    );

    return state;
  };

  function makeBridgeUnitConstructor<Y>(
    subscribeOrigin: (
      callback: StateSubscriberCallback<Y>,
      name: string,
    ) => () => void,
    getState: () => Y,
    setOriginState: (value: SetStateValue<Y>, ignoreList?: string[]) => void,
  ) {
    return function getBridgeUnit<U>(
      forwardStateConverter: (state: Y) => U,
      backwardStateConverter: (state: U) => Y,
      backwardFilterPredicate?: (state: U) => boolean,
    ): PrimaryStateUnit<U> {
      const bridgeInitialState = forwardStateConverter(getState());

      const bridgeUnit = makePrimaryUnit<U>(
        bridgeInitialState,
        undefined,
        backwardFilterPredicate,
      );

      bridgeUnit.subscribe({
        name: forwarderToOriginName,
        callback: state => {
          setOriginState(backwardStateConverter(state), [
            forwarderToBridgeName,
          ]);
        },
      });

      subscribeOrigin(
        state =>
          bridgeUnit.setState(forwardStateConverter(state), [
            forwarderToOriginName,
          ]),
        forwarderToBridgeName,
      );

      return bridgeUnit;
    };
  }

  const getBridgeState = () =>
    unitState === notInitialized
      ? typeof initialState === 'function'
        ? (initialState as () => T)()
        : initialState
      : unitState;

  const getBridgeUnit = makeBridgeUnitConstructor(
    subscribe,
    getBridgeState,
    setState,
  );

  function useBridgeUnitMemo<Y>(
    forwardStateConverter: (state: T) => Y,
    backwardStateConverter: (state: Y) => T,
    backwardFilterPredicate?: (state: Y) => boolean,
  ): PrimaryStateUnit<Y> {
    return useMemo(
      () =>
        getBridgeUnit(
          forwardStateConverter,
          backwardStateConverter,
          backwardFilterPredicate,
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [getBridgeUnit],
    );
  }

  return {
    kind: 'primary',
    isStateUnit: true,
    initialState,
    useState: useUnitState,
    setState,
    getState: getUnitState,
    getBridgeUnit,
    useBridgeUnitMemo,
    resetState: () => setState(initialState),
    subscribe: ({ callback, name }) => subscribe(callback, name),
    subscribeStart: ({ callback, name }) => subscribeStart(callback, name),
  };
}

export function usePrimaryUnit<T>(
  initialState: T | (() => T),
  debugData?: UnitDebugData,
) {
  const [state] = useState(() => makePrimaryUnit(initialState, debugData));
  return state;
}
