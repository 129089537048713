import { Merge } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  user: Partial<
    Merge<
      M.User,
      {
        subjects: (string | { lang: string; value: string })[];
        location: Pick<M.User['location'], 'latitude' | 'longitude'>;
      }
    >
  >;
};

type OutputData = {
  user: M.User;
};

export const patch = makeService<InputData, OutputData, M.User>(
  () => 'user/me/',
  'api-v2-patch',
  false,
  ({ user }) => user,
);
