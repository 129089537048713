import React from 'react';
import { useLocation } from 'react-router';

import { SharedParams } from 'pages/routeTree';
import * as Routing from 'services/Routing';

type Props = {};

function LocationObserver({}: Props) {
  const location = useLocation();

  const params = Routing.parseSearchParams<SharedParams>(location.search);

  if (process.env.BUILD_TARGET === 'server') {
    return null;
  }

  if (params?.profile === undefined) {
    return null;
  }

  window.location.replace(
    `${process.env.RAZZLE_PATH_BASENAME}/oauth2/receiver/?profile=${params.profile}&target_uri=${window.location.href}`,
  );

  return null;
}

export const Component = React.memo(LocationObserver);
