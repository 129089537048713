import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {};

type OutputData = { user: M.User };

export const get = makeService<InputData, OutputData, M.User>(
  () => 'user/me/',
  'api-v2-get',
  false,
  ({ user }) => user,
);
