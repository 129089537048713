import * as M from 'types/serverModels';
import { isValidUrl } from 'utils/url';

export function hasImages(
  questionAnswer: unknown,
): questionAnswer is M.Image | M.Image[] {
  return (
    (typeof questionAnswer === 'object' &&
      questionAnswer !== null &&
      'thumb' in questionAnswer &&
      typeof questionAnswer.thumb === 'string' &&
      isValidUrl(questionAnswer.thumb)) ||
    (Array.isArray(questionAnswer) &&
      typeof questionAnswer[0] === 'object' &&
      questionAnswer[0] !== null &&
      'thumb' in questionAnswer[0] &&
      typeof questionAnswer[0].thumb === 'string' &&
      isValidUrl(questionAnswer[0].thumb))
  );
}

export function hasText(questionAnswer: unknown): questionAnswer is string {
  return typeof questionAnswer === 'string' && questionAnswer.length > 0;
}

export function hasNumber(questionAnswer: unknown): questionAnswer is number {
  return typeof questionAnswer === 'number';
}

export function hasVariantSelection(
  questionAnswer: unknown,
): questionAnswer is string[] {
  return (
    Array.isArray(questionAnswer) &&
    questionAnswer.length > 0 &&
    questionAnswer.every(x => typeof x === 'string')
  );
}

export function hasVideo(
  questionAnswer: unknown,
): questionAnswer is M.VideoFromHosting {
  return (
    typeof questionAnswer === 'object' &&
    questionAnswer !== null &&
    'host' in questionAnswer &&
    (questionAnswer.host === 'youtube' ||
      questionAnswer.host === 'vimeo' ||
      questionAnswer.host === 'rutube' ||
      questionAnswer.host === 'vk' ||
      questionAnswer.host === 'dzen')
  );
}

export function hasFile(questionAnswer: unknown): questionAnswer is string {
  return isValidUrl(questionAnswer);
}
