import { makeSharedEntryReference } from 'services/I18n';
import { PROJECT_BOOK_TASK_PRIORITY } from 'shared/constants';

export const requiredFieldReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidURLSyntaxReference = makeSharedEntryReference(
  data => data.validationErrors.invalidURLSyntax,
);

export const addButtonLabelReference = makeSharedEntryReference(
  data => data.addButtonLabel,
);

export const editButtonLabelReference = makeSharedEntryReference(
  data => data.editButtonLabel,
);

export const deleteButtonLabelReference = makeSharedEntryReference(
  data => data.deleteButtonLabel,
);

export const doneButtonLabelReference = makeSharedEntryReference(
  data => data.doneButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const projectBookTaskNullStateReference = makeSharedEntryReference(
  data => data.projectBook.task.state.null,
);

export const projectBookTaskPriorityReferences = {
  [PROJECT_BOOK_TASK_PRIORITY.high]: makeSharedEntryReference(
    data => data.projectBook.task.priority.high,
  ),
  [PROJECT_BOOK_TASK_PRIORITY.medium]: makeSharedEntryReference(
    data => data.projectBook.task.priority.medium,
  ),
  [PROJECT_BOOK_TASK_PRIORITY.low]: makeSharedEntryReference(
    data => data.projectBook.task.priority.low,
  ),
};

export const projectBookTaskPriorityTooltipReferences = {
  [PROJECT_BOOK_TASK_PRIORITY.high]: makeSharedEntryReference(
    data => data.projectBook.task.priorityTooltip.high,
  ),
  [PROJECT_BOOK_TASK_PRIORITY.medium]: makeSharedEntryReference(
    data => data.projectBook.task.priorityTooltip.medium,
  ),
  [PROJECT_BOOK_TASK_PRIORITY.low]: makeSharedEntryReference(
    data => data.projectBook.task.priorityTooltip.low,
  ),
};

export const projectBookBidirectionalLinkedTaskTypeReferences = {
  relatesTo: makeSharedEntryReference(
    data => data.projectBook.task.linkedTasks.type.relatesTo,
  ),
  precedes: makeSharedEntryReference(
    data => data.projectBook.task.linkedTasks.type.precedes,
  ),
  precededBy: makeSharedEntryReference(
    data => data.projectBook.task.linkedTasks.type.precededBy,
  ),
  blocks: makeSharedEntryReference(
    data => data.projectBook.task.linkedTasks.type.blocks,
  ),
  blockedBy: makeSharedEntryReference(
    data => data.projectBook.task.linkedTasks.type.blockedBy,
  ),
};
