import { makePrimaryUnit } from 'utils/State';

type AuthData = {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
};

const authDataUnit = makePrimaryUnit<AuthData | null>(null);

const broadcastChannel =
  process.env.BUILD_TARGET === 'client'
    ? new BroadcastChannel('auth-data')
    : null;

broadcastChannel?.addEventListener('message', event => {
  authDataUnit.setState(event.data);
});

export function setAuthData(data: AuthData | null = null) {
  authDataUnit.setState(data);

  broadcastChannel?.postMessage(data);
}

export function useAuthData() {
  const authData = authDataUnit.useState();

  return authData;
}

export function getAccessToken() {
  return authDataUnit.getState()?.accessToken || null;
}

export function useAccessToken() {
  const accessToken = authDataUnit.useState()?.accessToken || null;

  return accessToken;
}
