import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  login: string;
};

type OutputData = { user: M.User };

export const get = makeService<InputData, OutputData, M.User>(
  ({ login }) => `user/profile/${login}/`,
  'api-v2-get',
  false,
  ({ user }) => user,
);
