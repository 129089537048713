import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { validate } from 'uuid';

import { API, DataPrefetch } from 'services';
import { Page } from 'services';

import { CoursePageRouteTree, routeTree } from '../../routeTree';
import * as Course from './Course';
import * as features from './features';

type Props = {};

const path = routeTree.LANG.course.list.COURSE.getPath();

const coursePrefetcher = DataPrefetch.makeDataPrefetcher(
  'course',
  routeTree.LANG.course.list.COURSE.getPath() + '/:rest*',
  ({ course }) => {
    if (validate(course)) {
      return API.services.courseRead.callPromised({ uuid: course });
    }

    return API.services.courseRead.callPromised({ code: course });
  },
);

function Content({}: Props) {
  const match = useRouteMatch<{ course: string }>({ path });

  const uuidOrCode = match!.params.course;

  const accessToken = API.useAccessToken();

  const callStateUnit = API.services.courseRead.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.courseRead.useCall(callStateUnit);

  const coursePageRouteTree: CoursePageRouteTree = useMemo(() => {
    return {
      kind: 'course',
      tree: routeTree.LANG.course.list.COURSE,
    };
  }, []);

  useEffect(() => {
    if (coursePrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    if (validate(uuidOrCode)) {
      call({ uuid: uuidOrCode });
    } else {
      call({ code: uuidOrCode });
    }
  }, [uuidOrCode, accessToken, call]);

  useEffect(
    () => () => {
      coursePrefetcher.deleteData();
    },
    [accessToken],
  );

  const prefetchedCourse = coursePrefetcher.getData();
  if (
    prefetchedCourse !== DataPrefetch.notFetched &&
    prefetchedCourse !== undefined
  ) {
    return (
      <Course.Component
        course={prefetchedCourse.courseReadCourse}
        products={prefetchedCourse.products}
        coursePageRouteTree={coursePageRouteTree}
      />
    );
  }

  return API.renderCallState(callState, {
    successful: data => (
      <Course.Component
        course={data.data.courseReadCourse}
        products={data.data.products}
        coursePageRouteTree={coursePageRouteTree}
      />
    ),
  });
}

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: Content,
  features: Object.values(features).map(x => x.feature),
});
