import * as TS from 'types';
import * as M from 'types/serverModels';
import { Leaves } from 'utils/types';

import { makeService } from '../utils';

type InputData = {
  lang: TS.Language;
  limit?: number;
  start?: number;
  sort?: { property: Leaves<Required<M.UserData>>; direction: 'ASC' | 'DESC' };
  filter?: {
    substring?: string;
    logins?: string[];
    role?: [M.UserRole];
    name?: string;
    langs?: string;
    subjects?: string;
    interests?: string;
  };
};

type OutputData = { users: M.UserData[] };

export const list = makeService<InputData, OutputData, M.UserData[]>(
  (data, convertToSearchParams) =>
    `user/?${convertToSearchParams({
      ...data,
      filter: JSON.stringify(data.filter),
    })}`,
  'api-v2-get',
  false,
  ({ users }) => users,
);
