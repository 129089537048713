import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
  set: M.ProjectBookChangeNotificationStatus;
};

type QueryInputData = {
  user?: M.UserXShort['login'];
  filter?: FilterQuery<M.ProjectBookChange>;
};

type BodyInputData = {
  bodySet: M.ProjectBookChangeNotificationStatus;
};

type InputData = PathInputData & QueryInputData & BodyInputData;

type OutputData = void;

function extractResponse(data: OutputData): OutputData {
  return data;
}

export const listSet = makeService<InputData, OutputData, OutputData>(
  ({ uuid, set, ticket, user, filter }, convertToSearchParams) => {
    return `project_book/${uuid}/notifications/${set}/?${convertToSearchParams({
      ticket,
      user,
      filter,
    })}`;
  },
  'api-v2-put',
  false,
  extractResponse,
  {
    extractBodyInput: ({ bodySet }) => {
      return { set: bodySet };
    },
  },
);
