import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../../../utils';

type InputData = {
  lang?: TS.Language;
  filter: {
    substring?: string;
    logins?: string[];
    role?: M.UserRole[];
    name?: string;
    langs?: string;
    subjects?: string;
    interests?: string;
    group?: M.UUID;
    project?: M.UUID;
    course?: M.UUID;
  };
};

type OutputData = { statistics: M.UserMapListStatistics };

export const get = makeService<InputData, OutputData>(
  ({ filter, ...data }, convertToSearchParams) =>
    `user/map/statistics/?${convertToSearchParams({
      ...data,
      filter: JSON.stringify(filter),
    })}`,
  'api-v2-get',
  false,
);
