import * as M from 'types/serverModels';

import { matchEvaluationCriteria } from './matchEvaluationCriteria';

export function findCriteria(
  data: M.ProjectBookChange['change'],
  projectBook: M.ProjectBook,
) {
  const evaluationCriteria = matchEvaluationCriteria(data);

  return evaluationCriteria
    ? (() => {
        const { skills } = projectBook;

        if (!skills?.items) {
          return undefined;
        }

        for (let i = 0; i < skills.items.length; i++) {
          const criteria = skills.items[i].criteria?.find(
            x => x.uuid === evaluationCriteria,
          );

          if (criteria) return criteria;
        }
      })()
    : undefined;
}
