import React, { ReactNode } from 'react';
import { useLocation } from 'react-router';

import { Link } from 'components';
import { Device } from 'services';
import { block } from 'utils/classname';

import * as UnderlinedLabel from '../UnderlinedLabel';
import { isLinkEntryActive } from '../utils';
import * as Mobile from './Mobile';
import './style.scss';

const b = block('main-menu-root-link-entry');

type Props = {
  href: string;
  useLabel(): ReactNode;
  isActive?(route: string): boolean;
};

function LinkLabel({ href, useLabel, isActive }: Props) {
  const location = useLocation();

  const underlined = isLinkEntryActive({ href, isActive }, location);

  return (
    <UnderlinedLabel.Component underlined={underlined}>
      <Link.Component className={b()} href={href} size="l" weight="normal">
        {useLabel()}
      </Link.Component>
    </UnderlinedLabel.Component>
  );
}

export const Component = React.memo(LinkLabel);

export function makeRootLinkEntry(props: Props) {
  return () => {
    const device = Device.unit.useState();

    switch (device) {
      case 'desktop-s':
      case 'mobile':
        return <Mobile.Component {...props} />;

      default:
        return <Component {...props} />;
    }
  };
}
