import * as M from 'types/serverModels';
import { makeMapping } from 'utils/collection';

import { makeService } from '../../utils';

type InputData = {
  users: (Pick<M.User, 'name' | 'login' | 'email' | 'role'> & {
    uuid: M.UUID;
    password: string;
    group: M.Group['uuid'];
  })[];
};

type APIOutputData = {
  users: (Pick<M.User, 'name' | 'login' | 'email' | 'role'> & {
    uuid: M.UUID;
    password: string;
  } & (
      | { status: 1 }
      | {
          status: 0;
          error: Partial<
            Record<
              'login',
              ('incorrectLogin' | 'occupiedLogin' | 'notUniqueLogin')[]
            > &
              Record<
                'email',
                ('occupiedEmail' | 'notUniqueEmail' | 'badEmailFormat')[]
              > &
              Record<'password', 'badPassword'[]>
          >;
        }
    ))[];
};

type OutputData = Record<
  APIOutputData['users'][number]['uuid'],
  APIOutputData['users'][number]
>;

function extractResponse(data: APIOutputData): OutputData {
  return makeMapping(
    data.users,
    x => x.uuid,
    x => x,
  );
}

export const post = makeService<InputData, APIOutputData, OutputData>(
  () => 'user/batch',
  'api-v2-post',
  false,
  extractResponse,
);
