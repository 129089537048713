/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react';

import { Preloader } from 'components';
import {
  LocationObserverModal,
  PayWall,
  ProfileLoadErrorModal,
} from 'features/global';
import * as MainLayout from 'features/global/MainLayout';
import * as pages from 'pages';
import {
  Notification,
  Routing,
  UserFetching,
  OAuth,
  Viewport,
  Device,
  Page,
  API,
} from 'services';
import { featureNamesSet } from 'services/Feature';
import 'style/fonts/fonts.scss';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import './App.scss';
import * as Providers from './Providers';

const b = block('app');

if (process.env.BUILD_TARGET === 'client') {
  const handleDocumentLoad = () => {
    areResourcesLoadedUnit.setState(true);
  };

  window.addEventListener('load', handleDocumentLoad);
}

const areResourcesLoadedUnit = makePrimaryUnit(false);

function App() {
  const areResourcesLoaded = areResourcesLoadedUnit.useState();

  const pagesList = Object.values(pages);

  useEffect(() => {
    return () => {
      featureNamesSet.clear();
    };
  }, []);

  return (
    <Providers.Component>
      <API.socket.ClientScriptInitializer.Component />
      <API.socket.UserSocketInitializer.Component />
      <Notification.Poller.Component />
      <Viewport.ViewportInfoInitializer.Component />
      <Device.Initializer.Component />
      <Routing.HistoryInitializer.Component />
      <Routing.LocationChangeListener.Component />
      <Page.ScrollBehaviorInitializer.Component />
      <UserFetching.UserFetcher.Component />
      <ProfileLoadErrorModal.Component />
      <OAuth.LocationObserver.Component />
      <OAuth.TokenRefresh.Component />
      <LocationObserverModal.Component />
      {!areResourcesLoaded && (
        <Preloader.variants.Dots.Component className={b('preloader')} />
      )}
      <div
        className={b()}
        style={{ display: areResourcesLoaded ? 'block' : 'none' }}
      >
        <MainLayout.Component>
          {pagesList.map((Page, index) => (
            <Page.Component key={index} />
          ))}
        </MainLayout.Component>
      </div>
      <PayWall.Component />
    </Providers.Component>
  );
}

export const Component = App;
export default Component;
