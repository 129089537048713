import * as M from 'types/serverModels';

import { makeService } from '../../../../utils';

type InputData = {
  schoolID: M.UUID;
  keyword: string;
};

type APIOutputData = {
  student: M.MeschStudent[];
};

export const get = makeService<InputData, APIOutputData>(
  ({ ticket, schoolID, keyword }, convertToSearchParams) =>
    `mesch/school/${schoolID}/search/student/?${convertToSearchParams({ ticket, keyword })}`,
  'api-v2-get',
);
