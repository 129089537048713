export function scrollToElement(
  elem: HTMLElement,
  options: ScrollIntoViewOptions = { behavior: 'smooth' },
): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => {
      listenDocumentScrollEnd(resolve);

      elem.scrollIntoView(options);
    }, 0);
  });
}

function listenDocumentScrollEnd(resolve: () => void) {
  const handleDocumentScroll = () => {
    clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(() => {
      document.removeEventListener('scroll', handleDocumentScroll);

      resolve();
    }, 100);
  };
  let scrollTimeout: NodeJS.Timeout;

  document.addEventListener('scroll', handleDocumentScroll, {
    capture: true,
  });

  handleDocumentScroll();
}
