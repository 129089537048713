import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  grant_type: 'password' | 'refresh_token';
  username?: string;
  password?: string;
  scope?: string;
  refresh_token?: string;
};

export const post = makeService<InputData, M.AuthData>(
  (params, convertToSearchParams) =>
    `oauth2/token/?${convertToSearchParams(params)}`,
  'api-v2-post',
  false,
);
