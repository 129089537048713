import * as M from 'types/serverModels';

export function getLinkType(
  type: M.ProjectBookLinkedTaskType,
): M.ProjectBookBidirectionalLinkedTaskType {
  switch (type) {
    case 'relates_to': {
      return 'relatesTo';
    }
    case 'precedes':
    case 'blocks': {
      return type;
    }
  }
}
