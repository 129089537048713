import { makeService } from '../../../utils';

type InputData = {
  password: string;
};

type OutputData = never;

export const put = makeService<InputData, OutputData, OutputData>(
  () => 'user/me/password/',
  'api-v2-put',
  false,
  data => data,
  { contentType: 'application/x-www-form-urlencoded' },
);
