import { makeService } from '../../../utils';

type InputData = {
  email: string;
};

type OutputData = never;

export const put = makeService<InputData, OutputData, OutputData>(
  () => 'user/password/reset',
  'api-v2-put',
  false,
  data => data,
  { contentType: 'application/x-www-form-urlencoded' },
);
