import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  qqfile: Blob;
};

type OutputData = {
  users: (Pick<M.User, 'name' | 'login' | 'email' | 'role'> & {
    uuid: M.UUID;
    password: string;
  } & (
      | { status: 1 }
      | {
          status: 0;
          error: Partial<
            Record<
              'login',
              ('incorrectLogin' | 'occupiedLogin' | 'notUniqueLogin')[]
            > &
              Record<
                'email',
                ('occupiedEmail' | 'notUniqueEmail' | 'badEmailFormat')[]
              > &
              Record<'password', 'badPassword'[]>
          >;
        }
    ))[];
};

export const post = makeService<InputData, OutputData, OutputData>(
  () => 'user/import',
  'api-v2-post',
  false,
  data => data,
  { contentType: 'multipart/form-data' },
);
