import * as M from 'types/serverModels';

import { BIDIRECTIONAL_TYPE_WEIGHT } from './BIDIRECTIONAL_TYPE_WEIGHT';

export function compare(
  a: M.ProjectBookBidirectionalLinkedTaskType,
  b: M.ProjectBookBidirectionalLinkedTaskType,
) {
  return BIDIRECTIONAL_TYPE_WEIGHT[a] - BIDIRECTIONAL_TYPE_WEIGHT[b];
}
