import * as M from 'types/serverModels';

export const BIDIRECTIONAL_TYPE_WEIGHT: Record<
  M.ProjectBookBidirectionalLinkedTaskType,
  number
> = {
  relatesTo: 0,
  precedes: 1,
  blocks: 2,
  precededBy: 3,
  blockedBy: 4,
} as const;
