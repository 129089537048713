import * as M from 'types/serverModels';

export function matchEvaluationCriteria(
  data: M.ProjectBookChange['change'],
): M.ProjectBookSkillCriteria['uuid'] | null {
  const [, criteriaUUID] =
    data?.path?.match(/evaluation\[user=.*\]\.criteria\.([a-z0-9-]+)/) || [];

  return criteriaUUID || null;
}
