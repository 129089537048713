import { pathToRegexp } from 'path-to-regexp';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { routeTree } from 'pages/routeTree';
import * as API from 'services/API';
import * as Routing from 'services/Routing';
import { userStateUnit as userUnit } from 'shared/stateUnits';

import { profileNotFoundCode } from './constants';
import { userProfileCallStateUnit } from './units';

type Props = {};

userUnit.setState(
  API.getAccessToken() ? { kind: 'pending' } : { kind: 'null' },
);

function UserFetcher({}: Props) {
  const accessToken = API.useAccessToken();
  const location = useLocation();

  const userProfilePath = routeTree.LANG.users.getPath();
  const userPersonalAccountPath = routeTree.LANG['personal-account'].getPath();

  const callState = userProfileCallStateUnit.useState();
  const call = API.services.user.me.get.useCall(userProfileCallStateUnit);

  useEffect(() => {
    if (accessToken === null) {
      userUnit.setState({ kind: 'null' });
    } else {
      call({});
    }
  }, [accessToken, call]);

  useEffect(() => {
    const previousLocation = Routing.getPreviousLocation();

    const haveGoneToUserProfileFromNotUserProfile =
      location.pathname.match(
        pathToRegexp(userProfilePath, [], { end: false }),
      ) !== null &&
      previousLocation?.pathname.match(
        pathToRegexp(userProfilePath, [], { end: false }),
      ) === null;
    const haveGoneToUserPersonalAccountFromNotUserPersonalAccount =
      location.pathname.match(
        pathToRegexp(userPersonalAccountPath, [], { end: false }),
      ) !== null &&
      previousLocation?.pathname.match(
        pathToRegexp(userPersonalAccountPath, [], { end: false }),
      ) === null;

    const shouldRefetchUser =
      haveGoneToUserProfileFromNotUserProfile ||
      haveGoneToUserPersonalAccountFromNotUserPersonalAccount;

    if (accessToken !== null && shouldRefetchUser) {
      call({});
    }
  }, [
    userProfilePath,
    userPersonalAccountPath,
    location.pathname,
    call,
    accessToken,
  ]);

  useEffect(() => {
    if (callState.kind === 'successful') {
      userUnit.setState({ kind: 'loaded', user: callState.data });
    } else if (callState.kind === 'pending') {
      userUnit.setState({ kind: 'pending' });
    } else if (
      callState.kind === 'error' &&
      callState.code === profileNotFoundCode
    ) {
      API.setAuthData(null);
    }
  }, [callState]);

  return null;
}

export const Component = React.memo(UserFetcher);
