import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../../../utils';

type InputData = {
  lang: TS.Language;
  bbox: string;
  resolution: number;
  filter: {
    substring?: string;
    logins?: string[];
    role?: M.UserRole[];
    name?: string;
    langs?: string;
    subjects?: string;
    interests?: string;
    group?: M.UUID;
    project?: M.UUID;
    course?: M.UUID;
  };
};

type OutputData = M.UserMapListData;

export const get = makeService<InputData, OutputData>(
  ({ filter, ...data }, convertToSearchParams) =>
    `user/map/clusters/?${convertToSearchParams({
      ...data,
      filter: JSON.stringify(filter),
    })}`,
  'api-v2-get',
  false,
);
