import * as M from 'types/serverModels';

export function getRefererLinkType(
  type: M.ProjectBookLinkedTaskType,
): M.ProjectBookBidirectionalLinkedTaskType {
  switch (type) {
    case 'relates_to': {
      return 'relatesTo';
    }
    case 'precedes': {
      return 'precededBy';
    }
    case 'blocks': {
      return 'blockedBy';
    }
  }
}
