import { Location } from 'history';

import { Routing } from 'services';

import type { LinkEntry } from '../../types';

export function isLinkEntryActive(
  entry: Pick<LinkEntry, 'href' | 'isActive'>,
  location: Location,
) {
  const isActiveByActive = entry.isActive?.(location.pathname);
  const isActiveByHref = Routing.routeMatches(location.pathname, entry.href);

  return isActiveByActive ?? isActiveByHref;
}
